import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navigation from "./pages/Navigation";
import "./scss/main.scss";
import ContactPage from "./pages/ContactPage";
import ServicePage from "./pages/ServicePage";
import AboutPage from "./pages/AboutPage";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
class App extends Component {
  state = {
    currentSlide: 0,
    isHamburgerActive: false
  };

  handleServiceScroll = () => {
    const service = document.querySelector(".service");
    service.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  };

  handleHamburger = () => {
    const menu = document.querySelector(".menu");
    menu.classList.toggle("active");
    this.setState({
      isHamburgerActive: !this.state.isHamburgerActive
    });
  };

  slideShow = () => {
    const slider = document.getElementById("slider");
    if (slider === null) {
      return null;
    } else {
      if (this.state.currentSlide < 3) {
        const currentSlide = this.state.currentSlide + 1;
        slider.style.left = `-${currentSlide}00vw`;

        this.setState({
          currentSlide
        });
      } else if (this.state.currentSlide === 3) {
        const currentSlide = 0;
        slider.style.left = `${currentSlide}vw`;

        this.setState({
          currentSlide
        });
      }
    }
  };

  scrollNavigation = () => {
    const changeScroll = window.scrollY;
    const nav = document.querySelector(".nav-logo");
    if (changeScroll > window.innerHeight / 1.5) {
      nav.classList.add("logo-modify");
    } else {
      nav.classList.remove("logo-modify");
    }
  };

  scrollClass = () => {
    const changeScroll = window.scrollY;
    if (document.querySelector(".header") !== null) {
      const serviceHeight =
        document.querySelector(".service").offsetTop - window.innerHeight / 2;
      const aboutHeight =
        document.querySelector(".about").offsetTop - window.innerHeight / 2;
      const contactHeight =
        document.querySelector(".contact").offsetTop - window.innerHeight / 2;
      if (changeScroll > serviceHeight) {
        document.querySelector(".service").classList.add("active");
      }
      if (changeScroll > aboutHeight) {
        document.querySelector(".about").classList.add("active");
      }
      if (changeScroll > contactHeight) {
        document.querySelector(".contact").classList.add("active");
      }
    } else if (document.querySelector(".service") !== null) {
      const serviceHeight =
        document.querySelector(".service").offsetTop - window.innerHeight / 2;
      if (changeScroll > serviceHeight) {
        document.querySelector(".service").classList.add("active");
      }
    }
  };

  loadPage = () => {
    if (document.querySelector(".header") === null) {
      if (document.querySelector(".about") !== null) {
        document.querySelector(".about").classList.add("active");
      } else if (document.querySelector(".contact") !== null) {
        document.querySelector(".contact").classList.add("active");
      } else if (document.querySelector(".service") !== null) {
        if (window.innerHeight === document.body.offsetHeight) {
          document.querySelector(".service").classList.add("active");
        }
      }
    }
  };

  slideTime = () => {
    setInterval(() => {
      this.slideShow();
    }, 5000);
  };
  componentDidMount() {
    this.slideTime();
    document.addEventListener("scroll", () => this.scrollNavigation());
    document.addEventListener("scroll", () => this.scrollClass());
    this.loadPage();
    
  }
  componentDidUpdate(){
    this.loadPage();
  }
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="App">
          <main className="main">
            <Route
              path="/"
              exact
              render={props => (
                <Home
                  currentSlide={this.state.currentSlide}
                  handleButton={this.handleServiceScroll}
                />
              )}
            />

            <Route path="/service" component={ServicePage}></Route>
            <Route path="/contact" component={ContactPage}></Route>
            <Route path="/about" component={AboutPage}></Route>
          </main>
          <nav className="nav">
            <Navigation
              handleClick={this.handleHamburger}
              hamburger={this.state.isHamburgerActive}
            />
          </nav>
        </div>
      </Router>
    );
  }
}

export default App;
