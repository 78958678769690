import React from "react";
import Header from "./home-content/Header";
import Service from "./home-content/Service";
import About from "./home-content/About";
import Contact from "./home-content/Contact";
const Home = props => {
  return (
    <div className="home">
      <header className="header">
        <Header
          currentSlide={props.currentSlide}
          handleButton={props.handleButton}
        />
      </header>
      <section className="service">
        <Service />
      </section>
      <section className="about">
        <About />
      </section>
      <section className="contact">
        <Contact />
      </section>
    </div>
  );
};

export default Home;
