import React from "react";

const Service = () => {
  return (
    <ul className="service-list">
      <li className="service-list__computer-diagnostics">
        <span>komputerowa diagnostyka</span>
      </li>
      <li className="service-list__engine-diagnostics">
        <span>diagnostyka silników</span>
      </li>
      <li className="service-list__suspension-systems">
        <span>naprawa układów zawieszenia</span>
      </li>
      <li className="service-list__gear-replacement">
        <span>wymiany rozrządów</span>
      </li>
      <li className="service-list__services">
        <span>serwisy pogwarancyjne</span>
      </li>
      <li className="service-list__servicing">
        <span>mechanika ogólna</span>
      </li>
    </ul>
  );
};

export default Service;
