import React from "react";
import Service from "./home-content/Service";

const ServicePage = () => {
  return (
    <>
      <div className="service-header">
        <div className="service-content">
          <h1 className="service-title">Usługi</h1>
          <p className="service-text">
            Oferujemy dla Państwa profesjonalny zakres usług:
          </p>
        </div>
      </div>
      <section className="service">
        <Service />
      </section>
    </>
  );
};

export default ServicePage;
