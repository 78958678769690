import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal, faStar, faCog } from "@fortawesome/free-solid-svg-icons";
const About = () => {
  return (
    <>
      <div className="about__content">
        <div className="about__photo"></div>
        <div className="about__info">
          <h1 className="about__title">Serwis samochodowy Marek Błahut</h1>
          <span className="about__text">
            Firma trwa ponad 20 lat. Staramy się wykonywać każdą naprawę
            dokładnie i z zaangażowaniem, tak aby klient był zadowolony.
            Zachęcamy do korzystania z naszych usług.
          </span>
        </div>
        <div className="about__advantages">
          <ul className="about__list">
            <li className="about__exp">
              <div className="about__logo">
                <FontAwesomeIcon icon={faMedal} />
              </div>
              <span className="about__text">Od 1997 roku na rynku</span>
            </li>
            <li className="about__prof">
              <div className="about__logo">
                <FontAwesomeIcon icon={faCog} />
              </div>
              <span className="about__text">Profesjonalne usługi</span>
            </li>
            <li className="about__quality">
              <div className="about__logo">
                <FontAwesomeIcon icon={faStar} />
              </div>
              <span className="about__text">Gwarancja jakości</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default About;
