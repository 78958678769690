import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneAlt,
  faBars,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ReactComponent as MBLogo } from "../img/logos/MBLogo.svg";

const mail = <FontAwesomeIcon icon={faEnvelope} />;
const phone = <FontAwesomeIcon icon={faPhoneAlt} />;
const bars = <FontAwesomeIcon icon={faBars} />;
const cross = <FontAwesomeIcon icon={faTimes} />;
const Navigation = props => {
  return (
    <>
      {/* logo */}
      <div className="nav-logo">
        <div className="nav-logo__logo">
          <MBLogo />
        </div>
        <div className="menu">
          <ul className="menu__pages">
            <li className="menu__home" onClick={() => props.handleClick()}>
              <Link to="/">Strona główna</Link>
            </li>
            <li className="menu__service" onClick={() => props.handleClick()}>
              <Link to="service">Usługi</Link>
            </li>
            <li className="menu__about" onClick={() => props.handleClick()}>
              <Link to="about">O nas</Link>
            </li>
            <li className="menu__contact" onClick={() => props.handleClick()}>
              <Link to="contact">Kontakt</Link>
            </li>
          </ul>
        </div>
        <div
          className="nav-logo__hamburger"
          onClick={() => props.handleClick()}
        >
          {props.hamburger ? cross : bars}
        </div>
        <div className="nav-logo__open">
          <h2 className="nav-logo__phone">Tel. 502 575 954</h2>
          <span className="nav-logo__hour">
            Pn - Pt: 8<sup>00</sup> do 16<sup>00</sup>
          </span>
        </div>
      </div>

      {/* contact */}
      <div className="nav-contact">
        <a className="nav-contact__email" href="mailto:marek@blahut.net.pl">
          {mail}
          <span className="nav-contact__email-text">napisz</span>
        </a>
        <a className="nav-contact__phone" href="tel:+48502575954">
          {phone}
          <span className="nav-contact__phone-text">zadzwoń</span>
        </a>
      </div>
    </>
  );
};

export default Navigation;
