import React from "react";
import Contact from "./home-content/Contact";
const ContactPage = () => {
  return (
    <>
      <section className="contact">
        <Contact />
      </section>
      <div className="contact-map">
        
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2578.165384138802!2d18.79911031604441!3d49.74533187938434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47141cf5aad6f1ef%3A0x1a3677afb18e2e2d!2sB%C5%82ahut%20Marek.%20Serwis%20samochodowy!5e0!3m2!1sen!2spl!4v1576175898251!5m2!1sen!2spl" width="100%" height="100%" frameborder="0"  ></iframe>
      </div>
    </>
  );
};

export default ContactPage;
