import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faPhoneAlt,
  faEnvelope,
  faTools
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <>
      <div className="contact-location info">
        <FontAwesomeIcon icon={faMapMarkedAlt} />
        <h2>lokalizacja:</h2>
        <span>ul. Folwarczna 5 43-450 Ustroń</span>
      </div>
      <div className="contact-phone info">
        <FontAwesomeIcon icon={faPhoneAlt} />
        <h2>Telefon:</h2>
        <span>kom. 502 575 954</span>
      </div>
      <div className="contact-email info">
        <FontAwesomeIcon icon={faEnvelope} />
        <h2>email:</h2>
        <span>marek@blahut.net.pl</span>
      </div>
      <div className="contact-work-time info">
        <FontAwesomeIcon icon={faTools} />
        <h2>godziny pracy:</h2>
        <span>8:00-16:00</span>
      </div>
    </>
  );
};

export default Contact;
