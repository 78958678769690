import React from "react";
import CarL from "../../img/large/car-large.jpg";
import CarS from "../../img/small/car-small.jpg";
import EngineL from "../../img/large/engine-large.jpg";
import EngineS from "../../img/small/engine-small.jpg";
import Engine2L from "../../img/large/engine-large-second.jpg";
import Engine2S from "../../img/small/engine-small-second.jpg";
import BuyingL from "../../img/large/buying-large.jpg";
import BuyingS from "../../img/small/buying-small.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import Shell from "../../img/logos/logo-1.svg";
import Magneti from "../../img/logos/logo-2.svg";
import { ReactComponent as MBHeader } from "../../img/logos/MBHeader.svg";
import { ReactComponent as MBHmobile } from "../../img/logos/MBHmobile.svg";
const photos = [
  {
    id: 0,
    name: "car",
    srcLarge: CarL,
    srcsmall: CarS
  },
  {
    id: 1,
    name: "engine",
    srcLarge: EngineL,
    srcsmall: EngineS
  },
  {
    id: 2,
    name: "buying",
    srcLarge: BuyingL,
    srcsmall: BuyingS
  },
  {
    id: 3,
    name: "engine2",
    srcLarge: Engine2L,
    srcsmall: Engine2S
  }
];
const arrow = <FontAwesomeIcon icon={faArrowAltCircleDown} />;
const Header = props => {
  const currentPhoto = photos.map(element => {
    let style = {
      backgroundImage: `url(${element.srcLarge})`
    };
    if (props.currentSlide === element.id) {
      return (
        <div
          key={element.id}
          className="slider__photo active"
          style={style}
          alt={element.name}
        ></div>
      );
    } else {
      return (
        <div
          key={element.id}
          className="slider__photo"
          style={style}
          alt={element.name}
        ></div>
      );
    }
  });
  return (
    <>
      <div className="header-content">
        <div className="header-content__images">
          <img className="header-content__shell" src={Shell} alt="shell"></img>
          <img
            className="header-content__magneti-marelli"
            src={Magneti}
            alt="shell"
          ></img>
        </div>
        {window.innerWidth > 768 ?  <div className="header-content__logo">
         <MBHeader />
         </div>  : 
        <div className="header-content__logo">
         <MBHmobile />
        </div> }
       
        <button
          className="header-content__button"
          onClick={() => props.handleButton()}
        >
          <span>Zakres usług</span> {arrow}
        </button>
      </div>

      <div className="slider" id="slider">
        {currentPhoto}
      </div>
    </>
  );
};

export default Header;
