import React from "react";
import About from "./home-content/About";
const AboutPage = () => {
  return (
    <section className="about">
      <About />
    </section>
  );
};

export default AboutPage;
